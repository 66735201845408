import { formatPrice } from "./utils";

enum ErrorCodes {
    ERROR_USER_NOT_FOUND = 'ERROR_USER_NOT_FOUND',
    ERROR_INVALID_CREDENTIALS = 'ERROR_INVALID_CREDENTIALS',
    ERROR_AGREEMENT_NOT_FOUND = 'ERROR_AGREEMENT_NOT_FOUND',
    ERROR_WRONG_VERIFICATION_PIN = 'ERROR_WRONG_VERIFICATION_PIN',
    ERROR_WRONG_VERIFICATION_ORG = 'ERROR_WRONG_VERIFICATION_ORG',
    ERROR_NO_ACCESS = 'ERROR_NO_ACCESS',
    ERROR_INVALID_DATA = "ERROR_INVALID_DATA",
    ERROR_INVALID_BID_TYPE = "ERROR_INVALID_BID_TYPE",
    ERROR_INVALID_AUCTION_ID = "ERROR_INVALID_AUCTION_ID",
    ERROR_INVALID_TIME = "ERROR_INVALID_TIME",
    ERROR_BID_TO_LOW_CURRENT = "ERROR_BID_TO_LOW_CURRENT",
    ERROR_BID_TO_LOW = "ERROR_BID_TO_LOW",
    ERROR_AUTO_BID_TO_LOW = "ERROR_AUTO_BID_TO_LOW",
    ERROR_BID_TO_HIGH = "ERROR_BID_TO_HIGH",
    ERROR_BID_MIN_TARFIFF = "ERROR_BID_MIN_TARFIFF",
    ERROR_AUTO_BID_MIN_TARFIFF = "ERROR_AUTO_BID_MIN_TARFIFF",
    ERROR_BID_UNDER_ALLOWED_AUTO_THRESHOLD = "ERROR_BID_UNDER_ALLOWED_AUTO_THRESHOLD",
    ERROR_BID_OVER_ALLOWED_AUTO_THRESHOLD = "ERROR_BID_OVER_ALLOWED_AUTO_THRESHOLD",
    ERROR_BELOW_STARTING_PRICE = "ERROR_BELOW_STARTING_PRICE",
    ERROR_NOT_EQUAL_TO_LEADING_BID = "ERROR_NOT_EQUAL_TO_LEADING_BID",
    ERROR_NO_ACTIVE_AGREEMENT = "ERROR_NO_ACTIVE_AGREEMENT",
    ERROR_USERNAME_ALREADY_EXISTS = "ERROR_USERNAME_ALREADY_EXISTS",
    ERROR_INVALID_PASSWORD = "ERROR_INVALID_PASSWORD",
    ERROR_PASSWORDS_DO_NOT_MATCH = "ERROR_PASSWORDS_DO_NOT_MATCH",
    ERROR_USER_CREATION_FAILED = "ERROR_USER_CREATION_FAILED",
    ERROR_FIELDS_MISSING = "ERROR_FIELDS_MISSING",
    ERROR_EMAIL_ALREADY_EXISTS = "ERROR_EMAIL_ALREADY_EXISTS",
    ERROR_INVALID_USERNAME = "ERROR_INVALID_USERNAME",
    ERROR_USER_EXIST = "ERROR_USER_EXISTS",
    ERROR_USER_EXISTS = "ERROR_USER_EXISTS",
    ERROR_COMPANY_NOT_ACTIVE = "ERROR_COMPANY_NOT_ACTIVE",
    ERROR_COMPANY_NOT_ELIGABLE = "ERROR_COMPANY_NOT_ELIGABLE",
    ERROR_PERSON_NOT_ALLOWED = "ERROR_PERSON_NOT_ALLOWED",
    ERROR_FIELDS_INVALID = "ERROR_FIELDS_INVALID",
    ERROR_NO_DELETE_OWN_FIELD = "ERROR_NO_DELETE_OWN_FIELD",
    ERROR_DELETE_USER = "ERROR_DELETE_USER",
    ERROR_ALIAS_EXISTS = "ERROR_ALIAS_EXISTS",
    ERROR_INVALID_REGISTRATION_PIN = "ERROR_INVALID_REGISTRATION_PIN",
    ERROR_ALIAS_UPDATE_FAILED = "ERROR_ALIAS_UPDATE_FAILED",
    ERROR_GENERIC = "ERROR_GENERIC",
    ERROR_CREDIT_SAFE_FAILED = "ERROR_CREDIT_SAFE_FAILED",
    ERROR_USER_INACTIVE = "ERROR_USER_INACTIVE",
    ERROR_TOKEN_FAILED = "ERROR_TOKEN_FAILED",
    ERROR_ALIAS_CREATE_FAIL = "ERROR_ALIAS_CREATE_FAIL",
    ERROR_USER_BLOCKED = "ERROR_USER_BLOCKED",
    ERROR_INVALID_SUBMITTER_ID = "ERROR_INVALID_SUBMITTER_ID",
    ERROR_INVALID_AGREEMENT_ID = "ERROR_INVALID_AGREEMENT_ID",
    ERROR_INVALID_USER_TYPE = "ERROR_INVALID_USER_TYPE",
    ERROR_PERSON_NOT_ALLOWED_VERIFY = "ERROR_PERSON_NOT_ALLOWED_VERIFY",
    ERROR_SVEA_CREATE_ORDER = "ERROR_SVEA_CREATE_ORDER",
    ERROR_SVEA_NO_AMOUNT = "ERROR_SVEA_NO_AMOUNT",
    ERROR_SVEA_NO_INVOICES = "ERROR_SVEA_NO_INVOICES",
    ERROR_SVEA_NO_RESPONSE = "ERROR_SVEA_NO_RESPONSE",
}

enum StatusCodes {
    STATUS_LEADING = "STATUS_LEADING",
    STATUS_LEADING_UPDATED = "STATUS_LEADING_UPDATED",
    STATUS_UPDATED_AUTOBID = "STATUS_UPDATED_AUTOBID",
    STATUS_AUTO_OVERBID = "STATUS_AUTO_OVERBID",
    STATUS_IDENTICAL_AUTOBID = "STATUS_IDENTICAL_AUTOBID",
    STATUS_SELF_OVERBID = "STATUS_SELF_OVERBID",
}

export const getErrorCodeMessage = (errorCode: string, errorDetails?: any, defaultErrorMessage = "", nativeLanguage = true) => {
    switch (errorCode) {
        case ErrorCodes.ERROR_SVEA_CREATE_ORDER:
            return nativeLanguage ? "Problem med att skapa fakturaorder" : "Problem creating invoice order";
        case ErrorCodes.ERROR_SVEA_NO_AMOUNT:
            return nativeLanguage ? "Totalt återstående belopp för valda fakturor måste vara högre än 0" : "Total remaining of selected invoices are not larger than 0 sek";
        case ErrorCodes.ERROR_SVEA_NO_INVOICES:
            return nativeLanguage ? "Inga fakturor angivna" : "No invoices provided";
        case ErrorCodes.ERROR_SVEA_NO_RESPONSE:
            return nativeLanguage ? `Om betallösningen inte laddas kontakta oss så hjälper vi dig` : `If the payment solution does not load, contact us and we will help you`;
        case ErrorCodes.ERROR_INVALID_USER_TYPE:
            return nativeLanguage ? "Ogiltig användartyp" : "Invalid user type";
        case ErrorCodes.ERROR_INVALID_AGREEMENT_ID:
            return nativeLanguage ? "Ogiltigt avtals-ID" : "Invalid agreement ID";
        case ErrorCodes.ERROR_INVALID_SUBMITTER_ID:
            return nativeLanguage ? "Ogiltigt inlämnar-ID" : "Invalid submitter ID";
        case ErrorCodes.ERROR_USER_BLOCKED:
            return nativeLanguage ? "Ditt konto är blockerat, vänligen kontakta oss för mer information" : "Your account is blocked, please contact us for more information";
        case ErrorCodes.ERROR_NO_ACCESS:
            return nativeLanguage ? "Vi kunde inte verifiera din behörighet, vänligen kontakta oss om problemet kvarstår" : "We could not verify your eligibility to use the service, please contact us if the problem persists";
        case ErrorCodes.ERROR_ALIAS_CREATE_FAIL:
            return nativeLanguage ? "Kunde inte koppla kontot, försök igen senare eller kontakta oss om problemet kvarstår" : "Could not link the account, please try again later";
        case ErrorCodes.ERROR_TOKEN_FAILED:
            return nativeLanguage ? "Kunde inte hämta uppgifter om kontot, försök igen senare" : "Could not fetch user token, please try again later";
        case ErrorCodes.ERROR_USER_INACTIVE:
            return "The user is inactive and is pending review, contact us for more information";
        case ErrorCodes.ERROR_CREDIT_SAFE_FAILED:
            return nativeLanguage ? "Kunde inte hämtaeditupplysning, försök igen senare" : "Could not fetch credit report, please try again later";
        case ErrorCodes.ERROR_GENERIC:
            return nativeLanguage ? "Ett fel uppstod, försök igen senare" : "An error occurred, please try again later";
        case ErrorCodes.ERROR_ALIAS_UPDATE_FAILED:
            return nativeLanguage ? "Kunde inte uppdatera medarbetaren, försök igen senare" : "Could not update the employee, please try again later";
        case ErrorCodes.ERROR_INVALID_REGISTRATION_PIN:
            return nativeLanguage ? "Personnummret är ogiltigt" : "The VAT number is invalid";
        case ErrorCodes.ERROR_ALIAS_EXISTS:
            return nativeLanguage ? "En medarbetare med detta personnummer finns redan. Vänligen kontrollera att du har angett rätt personnummer." : "An employee with this personal number already exists. Please check that you have entered the correct personal number.";
        case ErrorCodes.ERROR_USERNAME_ALREADY_EXISTS:
            return nativeLanguage ? "Användarnamnet finns redan" : "The username already exists";
        case ErrorCodes.ERROR_DELETE_USER:
            return nativeLanguage ? "Kunde inte ta bort användare" : "Could not delete user";
        case ErrorCodes.ERROR_NO_DELETE_OWN_FIELD:
            return nativeLanguage ? "Du kan inte ta bort ditt eget fält" : "You cannot delete your own field";
        case ErrorCodes.ERROR_FIELDS_INVALID:
            return nativeLanguage ? "Fel vid verifieringen av fälten, vänligen kontrollera att alla fält är korrekt ifyllda" : "Error verifying the fields, please check that all fields are filled in correctly";
        case ErrorCodes.ERROR_COMPANY_NOT_ACTIVE:
            return nativeLanguage ? "Företaget är inte aktivt och kan inte registrera sig" : "The company is not active and cannot register";
        case ErrorCodes.ERROR_COMPANY_NOT_ELIGABLE:
            return nativeLanguage ? "Företaget är inte berättigat att registrera/verifiera sig, kontakta oss så hjälper vi dig" : "The company is not eligible to register";
        case ErrorCodes.ERROR_PERSON_NOT_ALLOWED:
            return nativeLanguage ? "Du har inte behörighet att registrera detta företag då du inte är registrerad som firmatecknare, vänligen kontakta oss om du anser att detta är fel" : "You are not authorized to register this company, please contact tovek";
        case ErrorCodes.ERROR_PERSON_NOT_ALLOWED_VERIFY:
            return nativeLanguage ? "Du har inte behörighet att verifiera detta företag då du inte är registrerad som firmatecknare, vänligen kontakta oss om du anser att detta är fel" : "You are not authorized to verify this company, please contact tovek";
        case ErrorCodes.ERROR_INVALID_PASSWORD:
            return nativeLanguage ? "Lösenordet måste vara mellan 8 och 120 tecken långt och innehålla två av följande: versal, gemen, siffra, specialtecken" : "The password must be between 8 and 120 characters long and contain two of the following: uppercase, lowercase, number, special character";
        case ErrorCodes.ERROR_INVALID_USERNAME:
            return nativeLanguage ? "Användarnamnet får endast innehålla bokstäver (ej innehålla tovek), siffror och specialtecken (_-) och vara mellan 6-24 tecken långt" : "The username may only contain letters (not contain tovek), numbers and special characters (_-) and be between 6-24 characters long";
        case ErrorCodes.ERROR_PASSWORDS_DO_NOT_MATCH:
            return nativeLanguage ? "Lösenorden matchar inte." : "The passwords do not match.";
        case ErrorCodes.ERROR_USER_CREATION_FAILED:
            return nativeLanguage ? "Kunde inte skapa konto, försök igen." : "Could not create account, please try again.";
        case ErrorCodes.ERROR_FIELDS_MISSING:
            return nativeLanguage ? "Vänligen fyll i alla obligatoriska fält." : "Please fill in all required fields.";
        // case ErrorCodes.ERROR_EMAIL_ALREADY_EXISTS:
        //     return nativeLanguage ? "Ett konto med denna e-postadress finns redan." : "An account with this email address already exists.";
        case ErrorCodes.ERROR_USER_EXISTS:
            return nativeLanguage ? "Ett konto med detta personummer/företagsnummer finns redan." : "An account with this personal number/company number already exists.";
        case ErrorCodes.ERROR_USER_NOT_FOUND:
            return nativeLanguage ? "Användaren hittades inte. Försök igen." : "The user was not found. Please try again.";
        case ErrorCodes.ERROR_INVALID_CREDENTIALS:
            return nativeLanguage ? "Fel användarnamn eller lösenord. Försök igen." : "Incorrect username or password. Please try again.";
        case ErrorCodes.ERROR_AGREEMENT_NOT_FOUND:
            return nativeLanguage ? "Avtalet hittades inte, försök att verifiera om igen." : "The agreement was not found. Please try again.";
        case ErrorCodes.ERROR_WRONG_VERIFICATION_PIN:
            return nativeLanguage ? `Personummret du verifierade med stämmer inte överens med det kopplade personnummret <strong>${errorDetails?.maskedPin ? errorDetails?.maskedPin : ''}</strong>` : `The personal number you verified with does not match the linked personal number <strong>${errorDetails?.maskedPin ? errorDetails?.maskedPin : ''}</strong>`;
        case ErrorCodes.ERROR_WRONG_VERIFICATION_ORG:
            return nativeLanguage ? `Personummret du har verifierade med har ej behörighet att godkänna företaget med orgnr <strong>${errorDetails?.maskedPin ? errorDetails?.maskedPin : ''}</strong>` : `The personal number you verified with does not match the linked personal number for the company <strong>${errorDetails?.maskedPin ? errorDetails?.maskedPin : ''}</strong>`;
        case ErrorCodes.ERROR_NO_ACTIVE_AGREEMENT:
            return nativeLanguage ? "Du måste ha accepterat det senaste villkoren för att kunna lägga bud" : "You must accept the terms to place a bid";
        case ErrorCodes.ERROR_INVALID_DATA:
            return nativeLanguage ? "Ogiltiga bud-data" : "Invalid bid data";
        case ErrorCodes.ERROR_INVALID_BID_TYPE:
            return nativeLanguage ? "Ogiltigt budtyp" : "Invalid bid type";
        case ErrorCodes.ERROR_INVALID_TIME:
            return nativeLanguage ? "Auktionen har avslutats" : "The auction has ended";
        case ErrorCodes.ERROR_INVALID_AUCTION_ID:
            return nativeLanguage ? "Ogiltigt auktions-ID" : "Invalid auction ID";
        case ErrorCodes.ERROR_BID_TO_LOW_CURRENT:
            return nativeLanguage ? `För att bjuda över existerande bud måste du lägga minst <strong>${formatPrice(errorDetails?.min_bid_value)}</strong>` : "Your bid must be higher than the current bid";
        case ErrorCodes.ERROR_BID_TO_LOW:
            return nativeLanguage ? `Bud lägre än minsta bud. Minsta bud är ${formatPrice(errorDetails?.min_bid_value)}` : `The manual bid must be higher or equal to <strong></strong>`;
        case ErrorCodes.ERROR_AUTO_BID_TO_LOW:
            return nativeLanguage ? `Max-budet måste vara högre eller lika med <strong>${formatPrice(errorDetails?.min_bid_value)}</strong>` : `The auto bid must be higher or equal to <strong>${formatPrice(errorDetails?.min_bid_value)}</strong>`;
        case ErrorCodes.ERROR_BID_TO_HIGH:
            return nativeLanguage ? `Ditt direktbud är högre än högsta tillåtna. Högsta är <strong>${formatPrice(errorDetails?.max_bid_value)}</strong>` : `The manual bid is too high, you can place a maximum bid between <strong>${formatPrice(errorDetails?.min_bid_value)} - ${formatPrice(errorDetails?.max_bid_value)}</strong> or place an auto bid`;
        case ErrorCodes.ERROR_BELOW_STARTING_PRICE:
            return nativeLanguage ? `Ditt bud måste vara minst utgångspriset` : `Your bid must be higher than the starting price`;
        case ErrorCodes.ERROR_BID_MIN_TARFIFF:
            return nativeLanguage ? `Ditt bud måste vara högre eller lika med <strong>${formatPrice(errorDetails?.min_bid_value)}</strong>` : `Your bid must be higher or equal to <strong>${formatPrice(errorDetails?.min_bid_value)}</strong>`;
        case ErrorCodes.ERROR_AUTO_BID_MIN_TARFIFF:
            return nativeLanguage ? `Max-budet måste vara högre eller lika med <strong>${formatPrice(errorDetails?.min_bid_value)}</strong>` : `The auto bid must be higher or equal to <strong>${formatPrice(errorDetails?.min_bid_value)}</strong>`;
        case ErrorCodes.ERROR_NOT_EQUAL_TO_LEADING_BID:
            return nativeLanguage ? `För att lägga vinnande bud måste du lägga minst <strong>${formatPrice(errorDetails?.min_bid_value)}</strong>` : `Your bid must be higher than <strong>${formatPrice(errorDetails?.min_bid_value)}</strong>`;
        case ErrorCodes.ERROR_BID_UNDER_ALLOWED_AUTO_THRESHOLD:
            return nativeLanguage ? `Det fanns ett maxbud på <strong>${errorDetails?.leading_auto_bid}</strong> av <strong>${errorDetails?.leading_username}</strong> som nu är ledande bud. För att lägga ledande bud behöver du lägga minst <strong>${formatPrice(errorDetails?.min_bid_value)}</strong>` : `There was an auto bid of <strong>${errorDetails?.leading_auto_bid}</strong> by <strong>${errorDetails?.leading_username}</strong> that is now the leading bid. To place a leading bid you need to place at least <strong>${formatPrice(errorDetails?.min_bid_value)}</strong>`;
        case ErrorCodes.ERROR_BID_OVER_ALLOWED_AUTO_THRESHOLD:
            return nativeLanguage ? `Det fanns ett maxbud på <strong>${errorDetails?.leading_auto_bid}</strong> av <strong>${errorDetails?.leading_username}</strong> som nu är ledande bud. För att lägga ledande bud behöver du lägga minst <strong>${formatPrice(errorDetails?.min_bid_value)}</strong>` : `There was an auto bid of <strong>${errorDetails?.leading_auto_bid}</strong> by <strong>${errorDetails?.leading_username}</strong> that is now the leading bid. To place a leading bid you need to place at least <strong>${formatPrice(errorDetails?.min_bid_value)}</strong>`;
        default:
            return defaultErrorMessage;
    }
};

export const getStatusCodeMessage = (statusCode: string, defaultMessage = "") => {
    switch (statusCode) {
        case StatusCodes.STATUS_SELF_OVERBID:
            return "Du har redan det högsta budet.";
        case StatusCodes.STATUS_LEADING:
            return "Du leder just nu auktionen!";
        case StatusCodes.STATUS_LEADING_UPDATED:
            return "Ditt bud har uppdaterats och du leder fortfarande auktionen.";
        case StatusCodes.STATUS_UPDATED_AUTOBID:
            return "Ditt max-bud har uppdaterats.";
        case StatusCodes.STATUS_AUTO_OVERBID:
            return "Du har blivit automatiskt överbjuden av ett max-bud.";
        case StatusCodes.STATUS_IDENTICAL_AUTOBID:
            return "Du har redan ett max-bud på angivet belopp.";
        default:
            return defaultMessage;
    }
};
