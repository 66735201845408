
import { create } from "zustand";

interface ServerTimeState {
    userFavorites: number[];
    setUserFavorites: (userFavorites: number[]) => void;
}

const useUserFavoritesStore = create<ServerTimeState>((set) => ({
    userFavorites: [],
    setUserFavorites: (userFavorites: number[]) => set({ userFavorites }),
}));

export default useUserFavoritesStore;