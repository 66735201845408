import { parseDate, parseToDate } from "./utils"

// For requests
export const REVALIDATE_AUCTION_TIME = 30000 // 30 seconds
export const REVALIDATE_BID_TIME = 2000 // 2 seconds
export const REVALIDATE_USER_AUCTIONS_TIME = 2000 // 2 seconds
export const REVALIDATE_FILTERS_TIME = 30000 // 30 seconds
export const BID_TIME_REMAINING_COUNTDOWN = 60 * 2000 // 2 minutes in milliseconds
export const FETCH_AUCTION_FAVORITE_TIME = 5 * 60000 // 5 minutes

// Used to add extra time to the end time of an auction item to make sure the item is not ended before the user can place a bid
export const EXTRA_BID_TIME = 4000 // 4 seconds

// Filter settings, note: this is also set in the server for calculating first available page
export const AUCTION_ITEMS_PER_PAGE = 50

export const checkIfAuctionItemEnded = (itemEndTime: any, serverTime: any) => {
    let endTimeStr = parseDate(itemEndTime);

    if (isNaN(endTimeStr) || !serverTime) {
        return true;
    }

    let currentTime = parseToDate(serverTime).getTime();

    return endTimeStr + EXTRA_BID_TIME < currentTime;
};

